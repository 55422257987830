import React from 'react';
import styled from 'styled-components';
import { Breadcrumbs } from 'components/breadcrumbs';
import { Title } from 'components/title';
import { arrayOf, node, shape, string } from 'prop-types';
import { COLORS } from 'styles/constants/colors';
import MEDIA from 'styles/media';

const SHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.LIGHT_GREY};
  flex-direction: column;
  min-height: 170px;
  padding: 1.25rem 0.625rem;
  width: 80%;
  margin: 0 auto 3.125rem;

  ${MEDIA.max1024`
        width: 90%;
    `};
`;

export const PageHeader = ({ children, links, withBread = true }) => (
  <SHeader>
    <Breadcrumbs bread={children} links={links} withBread={withBread} />
    <Title>{children}</Title>
  </SHeader>
);

PageHeader.propTypes = {
  children: node.isRequired,
  links: arrayOf(
    shape({
      link: string,
      name: string,
    })
  ),
};

PageHeader.defaultProps = {
  links: [],
};
