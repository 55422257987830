import React from 'react';
import styled from 'styled-components';
import { LexiconGrid } from 'views/lexicon/components/LexiconGrid';
import { PageHeader } from 'components/page-header';
import MEDIA from 'styles/media';

const SContainer = styled.div`
  margin-top: 212px;
  padding-bottom: 3.75rem;

  ${MEDIA.max1024`
    margin-top: 100px;
  `};
`;

export const LexiconView = () => (
  <SContainer>
    <PageHeader>Leksykon</PageHeader>
    <LexiconGrid />
  </SContainer>
);
