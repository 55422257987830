import React from 'react';
import styled from 'styled-components';
import { node } from 'prop-types';
import { COLORS } from 'styles/constants/colors';

const STitle = styled.h1`
  text-align: center;
  font-size: 2.875rem;
  font-family: 'Abhaya Libre', serif;
  font-weight: bold;
  color: ${COLORS.LIGHT_BLACK};
  margin: 0.625rem 0 0;
`;

export const Title = ({ children }) => <STitle>{children}</STitle>;

Title.propTypes = {
  children: node.isRequired,
};
