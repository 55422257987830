import React from 'react';
import styled from 'styled-components';
import { TermsColumn } from 'views/lexicon/components/TermsColumn';
import LEXICON from 'jsons/lexicon.json';

const SGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 88.125rem;
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
`;

export const LexiconGrid = () => {
  const renderGrid = () => {
    return Object.keys(LEXICON).map((key) => (
      <TermsColumn key={key} letter={key} terms={LEXICON[key]} />
    ));
  };

  return <SGrid>{renderGrid()}</SGrid>;
};
