import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import { COLORS } from 'styles/constants/colors';

const SColumn = styled.div`
  min-width: 300px;
  margin-bottom: 2.5rem;
`;

const SLetter = styled.h3`
  font-size: 5.5rem;
  font-family: 'Abhaya Libre', serif;
  font-weight: bold;
  color: ${COLORS.CREAMY};
  text-transform: uppercase;
`;

const SLinkList = styled.ul`
  margin: 0;
  list-style: none;
`;

const SLinkItem = styled.li`
  margin-bottom: 0.5rem;
`;

const SLink = styled(Link)`
  text-decoration: none;
  color: ${COLORS.GREY};
`;

export const TermsColumn = ({ letter, terms }) => {
  const renderTerms = () => {
    return terms.map((term) => (
      <SLinkItem key={term.name}>
        <SLink to={term.link}>{term.name}</SLink>
      </SLinkItem>
    ));
  };

  return (
    <SColumn>
      <SLetter>{letter}</SLetter>
      <SLinkList>{renderTerms()}</SLinkList>
    </SColumn>
  );
};

TermsColumn.propTypes = {
  letter: string.isRequired,
  terms: arrayOf(
    shape({
      name: string,
      link: string,
    })
  ).isRequired,
};
