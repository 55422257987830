import idealeFb from '../images/ideale_fb.png';

export const TAGS = {
  home: {
    'og:url': 'https://ideale.pl',
    'og:type': 'article',
    'og:title': 'Gabinet Medycyny Estetycznej Warszawa Wola',
    'og:description':
      'Gabinet Medycyny Estetycznej Warszawa Wola. Podkreślamy Twoje Piękno. Lekarze Medycyny Estetycznej w IDEALE. Zabiegi Medycyny Estetycznej.',
    'og:image': idealeFb,
  },
  oferta: {
    'og:url': 'https://ideale.pl/zabiegi-medycyny-estetycznej/',
    'og:type': 'article',
    'og:title': 'Oferta zabiegów medycyny estetycznej | IDEALE',
    'og:description':
      'Pełna oferta zabiegów medycny estetycznej w IDEALE. Medycyna Estetyczna Warszawa Wola',
    'og:image': idealeFb,
  },
  leksykon: {
    'og:url': 'https://ideale.pl/leksykon/',
    'og:type': 'article',
    'og:title': 'Leksykon medycyny estetycznej | IDEALE',
    'og:description':
      'Leksykon Medycyny Estetyczne sygnowany marką IDEALE Medycyna Estetyczna, Wola, Warszawa. Poznaj wszystkie pojęcią związane z Twoim Pięknem. Poczuj się wyjątkowo już dziś.',
    'og:image': idealeFb,
  },
  cennik: {
    'og:url': 'https://ideale.pl/cennik/',
    'og:type': 'article',
    'og:title': 'Cennik Medycyny Estetycznej - IDEALE',
    'og:description':
      'Cennik zabiegów medycyny estetycznej w IDEALE. Podkreślamy Twoje Piękno. Oferujemy szeroki zakres zabiegów i nowoczesne rozwiązania.',
    'og:image': idealeFb,
  },
  usuwanieZmarszczek: {
    'og:url': 'https://ideale.pl/oferta/usuwanie-zmarszczek-mimicznych-bot/',
    'og:type': 'article',
    'og:title': 'Usuwanie i redukcja zmarszczek mimicznych | IDEALE',
    'og:description':
      'Usuwanie zmarszczek mimicznych w IDEALE Medycyna Estetyczna Warszawa Wola. Podkreślamy Twoje Piękno. Oferujemy szeroki zakres zabiegów, nowoczesne rozwiązania.',
    'og:image': idealeFb,
  },
  bruksizm: {
    'og:url': 'https://ideale.pl/oferta/leczenie-bruksizmu/',
    'og:type': 'article',
    'og:title': 'Leczenie Bruksizmu | IDEALE',
    'og:description':
      'Leczenie bruksizmu w IDEALE Medycyna Estetyczna Warszawa Wola. Podkreślamy Twoje Piękno. Oferujemy szeroki zakres zabiegów, nowoczesne rozwiązania.',
    'og:image': idealeFb,
  },
  leczenieNapotliwosci: {
    'og:url': 'https://ideale.pl/oferta/leczenie-nadpotliwosci-bot/',
    'og:type': 'article',
    'og:title': 'Leczenie nadpotliwości toksyną botulinową | IDEALE',
    'og:description':
      'Leczenie nadpotliwości z wykorzystaniem toksyny botulinowej, tzw. botoksu, w IDEALE Medycyna Estetyczna. Podkreślamy Twoje Piękno.',
    'og:image': idealeFb,
  },
  wolumetriaTwarzy: {
    'og:url': 'https://ideale.pl/oferta/wolumetria-twarzy/',
    'og:type': 'article',
    'og:title': 'Wolumetria twarzy | IDEALE',
    'og:description':
      'Wolumetria twarzy, lifiting wolumetryczny w IDEALE Warszawa Wola. Poczuj się wyjątkowo już dziś. Zabieg z wykorzystaniem kwasu hialuronowego.',
    'og:image': idealeFb,
  },
  modelowaniePowiekszanieUst: {
    'og:url': 'https://ideale.pl/oferta/modelowanie-powiekszanie-ust/',
    'og:type': 'article',
    'og:title': 'Modelowanie i powiększanie ust kwasem hialuronowym | IDEALE',
    'og:description':
      'Modelowanie ust, powiększanie ust za pomocą kwasu hialuronowego w IDEALE Medycyna Estetyczna, Wola, Warszawa. Podkreślamy Twoje Piękno.',
    'og:image': idealeFb,
  },
  wypelnianieZmarszczek: {
    'og:url':
      'https://ideale.pl/oferta/wypelnianie-zmarszczek-kwasem-hialuronowym/',
    'og:type': 'article',
    'og:title': 'Wypełnianie zmarszczek kwasem hialuronowym | IDEALE',
    'og:description':
      'Wypełnianie zmarszczek kwasem hialuronowym w IDEALE Medycyna Estetyczna, Wola, Warszawa. Oferujemy szeroki zakres zabiegów i nowoczesne rozwiązania.',
    'og:image': idealeFb,
  },
  wypelnianieDolinyLez: {
    'og:url': 'https://ideale.pl/oferta/wypelnianie-doliny-lez/',
    'og:type': 'article',
    'og:title': 'Wypełnianie doliny łez kwasem hialuronowym | IDEALE',
    'og:description':
      'Wypełnianie doliny łez kwasem hialuronowym w IDEALE Warszawa Wola. Oferujemy szeroki zakres zabiegów i nowoczesne rozwiązania.',
    'og:image': idealeFb,
  },
  mezoterapiaNeauviaHydroDeluxe: {
    'og:url': 'https://ideale.pl/oferta/mezoterapia-neauvia-hydro-deluxe/',
    'og:type': 'article',
    'og:title': 'Mezoterapia igłowa - kwas hialuronowy',
    'og:description':
      'Mezoterapia igłowa z kwasem hialuronowy w IDEALE Medycyna Estetyczna, Wola, Warszawa. Poczuj się wyjątkowo już dziś.',
    'og:image': idealeFb,
  },
  neauviaExplosion: {
    'og:url': 'https://ideale.pl/oferta/neauvia-explosion/',
    'og:type': 'article',
    'og:title': 'Skinbooster - kwas hialuronowy | IDEALE',
    'og:description':
      'Skinbooster - kwas hialuronowy. Zabieg Neauvia Explosion. Wolumetria w IDEALE Medycyna Estetyczna, Wola, Warszawa.',
    'og:image': idealeFb,
  },
  sculptra: {
    'og:url': 'https://ideale.pl/oferta/kwas-polimlekowy-sculptra/',
    'og:type': 'article',
    'og:title': 'Sculptra - zabieg z kwasem polimlekowym | IDEALE',
    'og:description':
      'Sculptra - zabieg z kwasem polimlekowym - silnym stymulatorem skóry w IDEALE Medycyna Estetyczna, Wola, Warszawa. Poczuj się wyjątkowo już dziś.',
    'og:image': idealeFb,
  },
  leczenieLysienia: {
    'og:url': 'https://ideale.pl/oferta/leczenie-lysienia/',
    'og:type': 'article',
    'og:title': 'Leczenie łysienia - mezoterapia igłowa skóry głowy | IDEALE',
    'og:description':
      'Leczenie łysienia, mezoterapia igłowa skóry głowy, leczenie łysienia w IDEALE Medycyna Estetyczna, Wola, Warszawa. Poczuj się wyjątkowo już dziś.',
    'og:image': idealeFb,
  },
  profhilo: {
    'og:url': 'https://ideale.pl/oferta/biostymulator-tkankowy-profhilo/',
    'og:type': 'article',
    'og:title': 'Profhilo - biostymulator tkankowy na twarz.',
    'og:description':
      'Biostymulator tkankowy na twarz Profhilo w IDEALE Medycyna Estetyczna Warszawa Wola. Oferujemy szeroki zakres zabiegów i nowoczesne rozwiązania.',
    'og:image': idealeFb,
  },
  nucleofill: {
    'og:url': 'https://ideale.pl/oferta/polinukleotydy-pluryal/',
    'og:type': 'article',
    'og:title': 'Pluryal - biostymulator tkankowy z polinukletydów.',
    'og:description':
      'Biostymulator tkankowy na bazie polinukleotydów, Pluryal, w IDEALE Medycyna Estetyczna Warszawa Wola. Oferujemy szeroki zakres zabiegów.',
    'og:image': idealeFb,
  },
  sunekos: {
    'og:url': 'https://ideale.pl/oferta/sunekos/',
    'og:type': 'article',
    'og:title': 'Sunekos - mezoterapia aminokwasowa - zabieg pod oczy | IDEALE',
    'og:description':
      'Mezoterapia aminokwasowa - zabieg pod oczy z wykorzystaniem preparatu Sunekos, IDEALE Medycyna Estetyczna, Wola, Warszawa. Poczuj się wyjątkowo już dziś.',
    'og:image': idealeFb,
  },
  gummySmile: {
    'og:url': 'https://ideale.pl/oferta/gummy-smile-usmiech-dziaslowy/',
    'og:type': 'article',
    'og:title': 'Korekta uśmiechu dziąsłowego "gummy smile" | IDEALE',
    'og:description':
      'Korekta uśmiechu dziąsłowego "gummy smile" w IDEALE Medycyna Estetyczna, Wola, Warszawa. Poczuj się wyjątkowo już dziś. Oferujemy szeroki zakres zabiegów.',
    'og:image': idealeFb,
  },
  jaluproZabieg: {
    'og:url': 'https://ideale.pl/oferta/jalupro/',
    'og:type': 'article',
    'og:title': 'Jalupro - aminokwasowa terapia zastępcza | IDEALE',
    'og:description':
      'Aminokwasowa terapia zastępcza w IDEALE Medycyna Estetyczna, Wola, Warszawa. Poczuj się wyjątkowo już dziś. Oferujemy szeroki zakres zabiegów.',
    'og:image': idealeFb,
  },
  karisma: {
    'og:url': 'https://ideale.pl/oferta/karisma/',
    'og:type': 'article',
    'og:title': 'Karisma Biostymulator Tkankowy na bazie Rh kolagen.',
    'og:description':
      'Biostymulator na bazie Rh kolagen Karismaw IDEALE Medycyna Estetyczna Warszawa Wola. Poczuj się wyjątkowo już dziś. Oferujemy szeroki zakres zabiegów.',
    'og:image': idealeFb,
  },
  electri: {
    'og:url': 'https://ideale.pl/oferta/electri/',
    'og:type': 'article',
    'og:title':
      'Mezoterapia igłowa skóry wokół oczu - kwas hialuronowy | IDEALE',
    'og:description':
      'Electri Mezoterapia igłowa skóry wokół oczu - kwas hialuronowy, w IDEALE Medycyna Estetyczna Warszawa Wola. Poczuj się wyjątkowo już dziś.',
    'og:image': idealeFb,
  },
  mezoterapia: {
    'og:url': 'https://ideale.pl/oferta/mezoterapia-mikroiglowa/',
    'og:type': 'article',
    'og:title': 'Mezoterapia mikroigłowa | IDEALE',
    'og:description':
      'Zabieg mezoterapii mikroigłowej w IDEALE Medycyna Estetyczna. Poczuj się wyjątkowo już dziś. Oferujemy szeroki zakres zabiegów i nowoczesne rozwiązania.',
    'og:image': idealeFb,
  },
  zabiegi: {
    'og:url': 'https://ideale.pl/zabiegi-medycyny-estetycznej',
    'og:type': 'article',
    'og:title': 'Poznaj Zabiegi Medycyny Estetycznej w IDEALE',
    'og:description':
      'Zabiegi medycyny estetycznej w IDEALE. Poznaj zabiegi, które wykonujemy. Oddaje się w ręce zaufanych lekarzy medycyny estetycznej.',
    'og:image': idealeFb,
  },
  zabiegiBotoks: {
    'og:url': 'https://ideale.pl/zabiegi-medycyny-estetycznej/botoks-warszawa',
    'og:type': 'article',
    'og:title': 'Zabiegi z toksyną botulinową w IDEALE, Wola Warszawa',
    'og:description':
      'Skuteczne i bezpieczne zabiegi z toksyną botulinową w IDEALE, Wola, Warszawa. Odmłodź swoją skórę, redukuj zmarszczki i ciesz się młodym wyglądem. Umów się na konsultację już dziś!',
    'og:image': idealeFb,
  },
  zabiegiKwasHialuronowy: {
    'og:url':
      'https://ideale.pl/zabiegi-medycyny-estetycznej/kwas-hialuronowy-na-twarz',
    'og:type': 'article',
    'og:title':
      'Zabiegi z kwasem hialuronowym na twarz w IDEALE, Wola Warszawa',
    'og:description':
      'Odkryj odmładzające zabiegi z kwasem hialuronowym na twarz w IDEALE, Wola, Warszawa. Zwiększ nawilżenie skóry, wygładź zmarszczki i uzyskaj naturalny efekt. Zarezerwuj wizytę już teraz!',
    'og:image': idealeFb,
  },
  zabiegiStymulatory: {
    'og:url':
      'https://ideale.pl/zabiegi-medycyny-estetycznej/stymulatory-tkankowe',
    'og:type': 'article',
    'og:title':
      'Zabiegi z użyciem stymulatorów tkankowych w IDEALE, Wola Warszawa',
    'og:description':
      'Odnów swoją skórę dzięki zabiegom z użyciem stymulatorów tkankowych w IDEALE, Wola, Warszawa. Popraw elastyczność, jędrność i gładkość skóry. Umów się na konsultację i poczuj różnicę!',
    'og:image': idealeFb,
  },
  zabiegiMezoterapia: {
    'og:url': 'https://ideale.pl/zabiegi-medycyny-estetycznej/mezoterapia',
    'og:type': 'article',
    'og:title': 'Zabiegi mezoterapii w IDEALE, Wola Warszawa',
    'og:description':
      'Zadbaj o zdrowie i wygląd swojej skóry dzięki zabiegom mezoterapii w IDEALE, Wola, Warszawa. Nawilżenie, regeneracja i odmłodzenie skóry. Umów się na wizytę już dziś!',
    'og:image': idealeFb,
  },
};
