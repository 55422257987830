import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import { COLORS } from 'styles/constants/colors';
import MEDIA from 'styles/media';

const SWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;

  li:not(:last-child):after {
    display: inline-block;
    margin: 0 0.25rem;
    content: '/';
  }
  ${MEDIA.max520`
    flex-wrap: wrap;
  `}
`;

const SBread = styled.li`
  color: ${COLORS.LIGHT_MEDIUM_GREY};
  flex-shrink: 0;

  &:last-child {
    color: ${COLORS.GREY};
  }

  ${MEDIA.max520`
    margin-bottom: 0;
 `}

  ${MEDIA.max420`
    max-width: 60%;
  `}
`;

const SBreadLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

export const Breadcrumbs = ({ bread, links, withBread }) => {
  const renderBreadcrumbs = () => {
    return links.map((link) => (
      <SBread key={link.name}>
        <SBreadLink to={link.link}>{link.name}</SBreadLink>
      </SBread>
    ));
  };

  return (
    <nav>
      <SWrapper>
        <SBread>
          <SBreadLink to="/">Home</SBreadLink>
        </SBread>
        {renderBreadcrumbs()}
        {withBread && (
          <SBread>
            <span>{bread}</span>
          </SBread>
        )}
      </SWrapper>
    </nav>
  );
};

Breadcrumbs.propTypes = {
  bread: string.isRequired,
  links: arrayOf(
    shape({
      name: string,
      link: string,
    })
  ),
};

Breadcrumbs.defaultProps = {
  links: [],
};
